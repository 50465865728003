import type { HeadlineResponse } from '@on3/api';
import { On3IconLockPremium } from '@on3/icons';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './ArticleCondensed.module.scss';

export const ArticleCondensed = ({
  title,
  isPremium,
  fullUrl,
}: HeadlineResponse) => {
  return (
    <article className={styles.container}>
      <TextLink className={styles.link} href={fullUrl} underline="none">
        <Text className={styles.title} color="primary" variant="h5">
          {title}
        </Text>
        {isPremium && <On3IconLockPremium />}
      </TextLink>
    </article>
  );
};
