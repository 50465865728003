import type { SimpleHeadlineResponse } from '@on3/api';
import { On3IconLockPremium } from '@on3/icons';
import { TextLink } from 'components/UI/Link/TextLink';

import styles from './PremiumArticle.module.scss';

export const PremiumArticle = ({ fullUrl, title }: SimpleHeadlineResponse) => {
  return (
    <article>
      <TextLink className={styles.headlines} href={fullUrl} variant="subtitle2">
        <On3IconLockPremium className={styles.icon} />
        <span className={styles.text}>{title}</span>
      </TextLink>
    </article>
  );
};
